<template>
  <app-page-wrapper v-if="contextualFaqEntries.length > 0" class="py-32">
    <app-page-section
      title="Formation professionnelle : les questions qu’on nous pose souvent. "
    >
      <ul :key="route.path" class="m-auto grid max-w-[900px]">
        <li v-for="(item, index) in contextualFaqEntries" :key="item.title">
          <app-divider v-if="index !== 0" class="my-2" />

          <app-expandable
            class="grid rounded px-6 transition"
            :model-value="index === 0"
            open-class="bg-gray-50"
            tag="section"
            @expand="onExpand(item)"
          >
            <template #trigger="{ open }">
              <header
                class="flex items-center justify-between gap-4 py-4 text-left"
              >
                <h3 class="text-lg font-semibold">
                  {{ item.title }}
                </h3>

                <app-icon
                  class="shrink-0 transition"
                  :class="{ 'rotate-180': open }"
                  icon="ph:caret-down"
                />
              </header>
            </template>

            <p class="prose pb-6" v-html="item.content" />
          </app-expandable>
        </li>
      </ul>

      <template #append>
        <growth-contact-button
          color="dark"
          origin="faq"
          size="xl"
          variant="outlined"
        >
          Contacter l'équipe Pollen
        </growth-contact-button>
      </template>
    </app-page-section>
  </app-page-wrapper>
</template>

<script lang="ts" setup>
import type { FaqEntryPage } from "~~/model/faq.model";

const { data } = useLazyFetch("/api/faq", { default: () => [] });

const { $analytics } = useNuxtApp();

function onExpand({ title }: FaqEntryPage) {
  return $analytics.track("faq_item_read", { title });
}

const route = useRoute();

const contextualFaqEntries = computed(
  () =>
    data.value?.filter((entry) => {
      const exclusions = entry.paths.filter((path) => path.startsWith("!"));
      const hasAtLeastOneMatch = entry.paths.some((path) =>
        isMatchingPath(route.path, path),
      );
      const isNotExcluded = exclusions.every((exclusion) =>
        isMatchingPath(route.path, exclusion),
      );

      return hasAtLeastOneMatch && isNotExcluded;
    }) ?? [],
);
</script>
